/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { bulkCreateTopic } from '../fn/topics/bulk-create-topic';
import { BulkCreateTopic$Params } from '../fn/topics/bulk-create-topic';
import { bulkDeleteTopic } from '../fn/topics/bulk-delete-topic';
import { BulkDeleteTopic$Params } from '../fn/topics/bulk-delete-topic';
import { getTopic } from '../fn/topics/get-topic';
import { GetTopic$Params } from '../fn/topics/get-topic';
import { listTopics } from '../fn/topics/list-topics';
import { ListTopics$Params } from '../fn/topics/list-topics';
import { Topic } from '../models/topic';
import { TopicWithArticlesResponsePage } from '../models/topic-with-articles-response-page';
import { updateTopic } from '../fn/topics/update-topic';
import { UpdateTopic$Params } from '../fn/topics/update-topic';

@Injectable({ providedIn: 'root' })
export class TopicsApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listTopics()` */
  static readonly ListTopicsPath = '/v0/topics';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listTopics()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTopics$Response(params?: ListTopics$Params, context?: HttpContext): Observable<StrictHttpResponse<TopicWithArticlesResponsePage>> {
    return listTopics(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listTopics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTopics(params?: ListTopics$Params, context?: HttpContext): Observable<TopicWithArticlesResponsePage> {
    return this.listTopics$Response(params, context).pipe(
      map((r: StrictHttpResponse<TopicWithArticlesResponsePage>): TopicWithArticlesResponsePage => r.body)
    );
  }

  /** Path part for operation `getTopic()` */
  static readonly GetTopicPath = '/v0/topics/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTopic()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTopic$Response(params: GetTopic$Params, context?: HttpContext): Observable<StrictHttpResponse<Topic>> {
    return getTopic(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTopic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTopic(params: GetTopic$Params, context?: HttpContext): Observable<Topic> {
    return this.getTopic$Response(params, context).pipe(
      map((r: StrictHttpResponse<Topic>): Topic => r.body)
    );
  }

  /** Path part for operation `updateTopic()` */
  static readonly UpdateTopicPath = '/v0/topics/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTopic()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTopic$Response(params: UpdateTopic$Params, context?: HttpContext): Observable<StrictHttpResponse<Topic>> {
    return updateTopic(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTopic$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTopic(params: UpdateTopic$Params, context?: HttpContext): Observable<Topic> {
    return this.updateTopic$Response(params, context).pipe(
      map((r: StrictHttpResponse<Topic>): Topic => r.body)
    );
  }

  /** Path part for operation `bulkCreateTopic()` */
  static readonly BulkCreateTopicPath = '/v0/topics/bulk-create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkCreateTopic()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkCreateTopic$Response(params: BulkCreateTopic$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Topic>>> {
    return bulkCreateTopic(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkCreateTopic$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkCreateTopic(params: BulkCreateTopic$Params, context?: HttpContext): Observable<Array<Topic>> {
    return this.bulkCreateTopic$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Topic>>): Array<Topic> => r.body)
    );
  }

  /** Path part for operation `bulkDeleteTopic()` */
  static readonly BulkDeleteTopicPath = '/v0/topics/bulk-delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkDeleteTopic()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkDeleteTopic$Response(params: BulkDeleteTopic$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return bulkDeleteTopic(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkDeleteTopic$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkDeleteTopic(params: BulkDeleteTopic$Params, context?: HttpContext): Observable<void> {
    return this.bulkDeleteTopic$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
