/* eslint-disable @typescript-eslint/no-shadow */
import { DestroyRef, Injectable, Signal, computed, inject, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

const equal = (a: unknown, b: unknown): boolean => {
  const aArray = Array.isArray(a);
  const bArray = Array.isArray(b);

  if (aArray && bArray) {
    return a.length === b.length && a.every((x, i) => `${x}` === `${b[i]}`);
  }

  if (aArray !== bArray) {
    return false;
  }

  return `${a}` === `${b}`;
};

export const qpMap = <T>(key: string, f: (x: string[]) => T) => {
  const route = inject(ActivatedRoute);
  const r = signal(f(route.snapshot.queryParamMap.getAll(key)), { equal });
  const sub = route.queryParamMap.subscribe((x) => r.set(f(x.getAll(key))));

  const navigator = inject(QueryParamsService);
  const queryRef = navigator.add(key, r);
  inject(DestroyRef).onDestroy(() => {
    sub.unsubscribe();
    navigator.remove(queryRef);
  });

  return r;
};

export const qpMap1 = <T>(key: string, f: (x: string) => T) =>
  qpMap(key, (values) => (values.length === 1 ? f(values[0]) : undefined));
export const qpMapN = <T>(key: string, f: (x: string) => T) => qpMap(key, (values) => values.map(f));

export const qpCast1 = <T extends string>(key: string) => qpMap1(key, (x) => x as T);
export const qpCastN = <T extends string>(key: string) => qpMap(key, (values) => values as T[]);

@Injectable({
  providedIn: 'root',
})
export class QueryParamsService {
  private readonly map = new Map<string, Signal<unknown>>();
  private readonly mapSignal = signal(this.map, { equal: () => false });

  readonly queryParams = computed(() =>
    Object.fromEntries([...this.mapSignal().entries()].map(([key, signal]) => [key, signal()])),
  );

  add(key: string, signal: Signal<unknown>) {
    if (this.map.has(key)) {
      throw new Error(`Key ${key} already registered`);
    }
    this.map.set(key, signal);

    this.mapSignal.set(this.map);
    return key;
  }

  remove(ref: string) {
    this.map.delete(ref);
    this.mapSignal.set(this.map);
  }
}
