/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { FeedbackResponsePage } from '../../models/feedback-response-page';

export interface ListFeedbacks$Params {
  offset?: number;
  limit?: number;
  sorting?: Array<'id' | '-id' | 'type' | '-type' | 'note' | '-note' | 'vote' | '-vote' | 'userId' | '-userId' | 'data' | '-data'>;
}

export function listFeedbacks(http: HttpClient, rootUrl: string, params?: ListFeedbacks$Params, context?: HttpContext): Observable<StrictHttpResponse<FeedbackResponsePage>> {
  const rb = new RequestBuilder(rootUrl, listFeedbacks.PATH, 'get');
  if (params) {
    rb.query('offset', params.offset, {"style":"form"});
    rb.query('limit', params.limit, {"style":"form"});
    rb.query('sorting', params.sorting, {"style":"form"});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<FeedbackResponsePage>;
    })
  );
}

listFeedbacks.PATH = '/v0/users/feedbacks';
