/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { disableUser } from '../fn/admin/disable-user';
import { DisableUser$Params } from '../fn/admin/disable-user';
import { enableUser } from '../fn/admin/enable-user';
import { EnableUser$Params } from '../fn/admin/enable-user';
import { getUser } from '../fn/admin/get-user';
import { GetUser$Params } from '../fn/admin/get-user';
import { listUsers } from '../fn/admin/list-users';
import { ListUsers$Params } from '../fn/admin/list-users';
import { UserItemAdmin } from '../models/user-item-admin';
import { UserItemAdminResponsePage } from '../models/user-item-admin-response-page';

@Injectable({ providedIn: 'root' })
export class AdminApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listUsers()` */
  static readonly ListUsersPath = '/v0/admin/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  listUsers$Response(params?: ListUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<UserItemAdminResponsePage>> {
    return listUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listUsers(params?: ListUsers$Params, context?: HttpContext): Observable<UserItemAdminResponsePage> {
    return this.listUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserItemAdminResponsePage>): UserItemAdminResponsePage => r.body)
    );
  }

  /** Path part for operation `getUser()` */
  static readonly GetUserPath = '/v0/admin/users/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Response(params: GetUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserItemAdmin>> {
    return getUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser(params: GetUser$Params, context?: HttpContext): Observable<UserItemAdmin> {
    return this.getUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserItemAdmin>): UserItemAdmin => r.body)
    );
  }

  /** Path part for operation `enableUser()` */
  static readonly EnableUserPath = '/v0/admin/users/{id}/enable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enableUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  enableUser$Response(params: EnableUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return enableUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enableUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enableUser(params: EnableUser$Params, context?: HttpContext): Observable<void> {
    return this.enableUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `disableUser()` */
  static readonly DisableUserPath = '/v0/admin/users/{id}/disable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `disableUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  disableUser$Response(params: DisableUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return disableUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `disableUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  disableUser(params: DisableUser$Params, context?: HttpContext): Observable<void> {
    return this.disableUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
