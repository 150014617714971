export const dateToJsDate = (date: string) => {
  const year = date.slice(0, 4);
  const month = date.slice(5, 7);
  const day = date.slice(8, 10);
  return new Date(+year, +month - 1, +day);
};

export const jsDateToDate = (date: Date) => {
  const year = `${date.getFullYear()}`.padStart(4, '0');
  const month = `${date.getMonth() + 1}`.padStart(2, '0');
  const day = `${date.getDate()}`.padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const DAY = 24 * 60 * 60 * 1000;

export const addDays = (x: string | Date | number, n: number) => new Date(new Date(x).getTime() + n * DAY);
