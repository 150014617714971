/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SearchRequest } from '../../models/search-request';
import { SearchResponsePage } from '../../models/search-response-page';

export interface SearchEmbeddings$Params {
  offset?: number;
  limit?: number;
      body: SearchRequest
}

export function searchEmbeddings(http: HttpClient, rootUrl: string, params: SearchEmbeddings$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchResponsePage>> {
  const rb = new RequestBuilder(rootUrl, searchEmbeddings.PATH, 'post');
  if (params) {
    rb.query('offset', params.offset, {"style":"form"});
    rb.query('limit', params.limit, {"style":"form"});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<SearchResponsePage>;
    })
  );
}

searchEmbeddings.PATH = '/v0/embeddings/search';
