/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Article } from '../models/article';
import { ArticleResponsePage } from '../models/article-response-page';
import { ArticleWithTopics } from '../models/article-with-topics';
import { createArticle } from '../fn/articles/create-article';
import { CreateArticle$Params } from '../fn/articles/create-article';
import { getArticle } from '../fn/articles/get-article';
import { GetArticle$Params } from '../fn/articles/get-article';
import { listArticles } from '../fn/articles/list-articles';
import { ListArticles$Params } from '../fn/articles/list-articles';
import { listRelatedArticles } from '../fn/articles/list-related-articles';
import { ListRelatedArticles$Params } from '../fn/articles/list-related-articles';
import { listRelatedTopics } from '../fn/articles/list-related-topics';
import { ListRelatedTopics$Params } from '../fn/articles/list-related-topics';
import { TopicWithArticles } from '../models/topic-with-articles';
import { updateArticle } from '../fn/articles/update-article';
import { UpdateArticle$Params } from '../fn/articles/update-article';

@Injectable({ providedIn: 'root' })
export class ArticlesApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listArticles()` */
  static readonly ListArticlesPath = '/v0/articles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listArticles()` instead.
   *
   * This method doesn't expect any request body.
   */
  listArticles$Response(params?: ListArticles$Params, context?: HttpContext): Observable<StrictHttpResponse<ArticleResponsePage>> {
    return listArticles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listArticles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listArticles(params?: ListArticles$Params, context?: HttpContext): Observable<ArticleResponsePage> {
    return this.listArticles$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArticleResponsePage>): ArticleResponsePage => r.body)
    );
  }

  /** Path part for operation `createArticle()` */
  static readonly CreateArticlePath = '/v0/articles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createArticle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createArticle$Response(params: CreateArticle$Params, context?: HttpContext): Observable<StrictHttpResponse<Article>> {
    return createArticle(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createArticle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createArticle(params: CreateArticle$Params, context?: HttpContext): Observable<Article> {
    return this.createArticle$Response(params, context).pipe(
      map((r: StrictHttpResponse<Article>): Article => r.body)
    );
  }

  /** Path part for operation `getArticle()` */
  static readonly GetArticlePath = '/v0/articles/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArticle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArticle$Response(params: GetArticle$Params, context?: HttpContext): Observable<StrictHttpResponse<ArticleWithTopics>> {
    return getArticle(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getArticle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArticle(params: GetArticle$Params, context?: HttpContext): Observable<ArticleWithTopics> {
    return this.getArticle$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArticleWithTopics>): ArticleWithTopics => r.body)
    );
  }

  /** Path part for operation `updateArticle()` */
  static readonly UpdateArticlePath = '/v0/articles/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateArticle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateArticle$Response(params: UpdateArticle$Params, context?: HttpContext): Observable<StrictHttpResponse<Article>> {
    return updateArticle(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateArticle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateArticle(params: UpdateArticle$Params, context?: HttpContext): Observable<Article> {
    return this.updateArticle$Response(params, context).pipe(
      map((r: StrictHttpResponse<Article>): Article => r.body)
    );
  }

  /** Path part for operation `listRelatedArticles()` */
  static readonly ListRelatedArticlesPath = '/v0/articles/{id}/related';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listRelatedArticles()` instead.
   *
   * This method doesn't expect any request body.
   */
  listRelatedArticles$Response(params: ListRelatedArticles$Params, context?: HttpContext): Observable<StrictHttpResponse<ArticleResponsePage>> {
    return listRelatedArticles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listRelatedArticles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listRelatedArticles(params: ListRelatedArticles$Params, context?: HttpContext): Observable<ArticleResponsePage> {
    return this.listRelatedArticles$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArticleResponsePage>): ArticleResponsePage => r.body)
    );
  }

  /** Path part for operation `listRelatedTopics()` */
  static readonly ListRelatedTopicsPath = '/v0/articles/{id}/related-topics';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listRelatedTopics()` instead.
   *
   * This method doesn't expect any request body.
   */
  listRelatedTopics$Response(params: ListRelatedTopics$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TopicWithArticles>>> {
    return listRelatedTopics(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listRelatedTopics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listRelatedTopics(params: ListRelatedTopics$Params, context?: HttpContext): Observable<Array<TopicWithArticles>> {
    return this.listRelatedTopics$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TopicWithArticles>>): Array<TopicWithArticles> => r.body)
    );
  }

}
