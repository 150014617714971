<div class="flex size-full items-start">
  <nav
    class="navbar flex h-full w-16 shrink-0 flex-col gap-8 overflow-y-auto border-r lg:w-56 px-0 pt-10 pb-6 lg:pl-12"
    [ngClass]="{
      'lg:!w-16 lg:!px-0 lg:!pt-10 lg:!pb-6 xl:!w-56 xl:!pr-0 xl:!pl-12': currentRoute().includes('explore'),
    }">
    <div
      class="mx-auto px-1 pb-1 rounded-md lg:p-0 lg:pt-2 lg:rounded-none lg:m-0 hover:cursor-pointer hover:bg-surface-hover"
      [ngClass]="{
        'lg:!mx-auto lg:!p-1 lg:!rounded-md xl:!p-0 xl:!pt-2 xl:!rounded-none xl:!m-0':
          currentRoute().includes('explore'),
      }"
      [routerLink]="['/explore']">
      <img
        src="./assets/svg/bodoni-prototype-logo.svg"
        alt="Bodoni Prototype"
        class="w-0 lg:w-[180px] lg:h-10"
        [ngClass]="{ 'lg:h-0 lg:!w-0 xl:!w-[180px] xl:!h-10': currentRoute().includes('explore') }" />
      <img
        src="./../../../favicon.ico"
        alt="Bodoni Prototype"
        class="w-6 lg:w-0"
        [ngClass]="{ 'lg:!w-6 xl:!w-0': currentRoute().includes('explore') }" />
    </div>

    <div class="flex flex-col grow gap-1.5">
      <a
        class="navbar-link flex flex-row"
        routerLinkActive="active"
        [ngClass]="{ 'xl:!px-3 lg:justify-center xl:justify-start': currentRoute().includes('explore') }"
        [routerLink]="['/explore']">
        <svg-icon
          class="text-slate-500 min-w-5 min-h-5 size-5"
          key="compass-line"
          routerLinkActive="!text-accent bg-teal-50"></svg-icon>
        <span
          class="hidden lg:flex min-w-max transition-all"
          [ngClass]="{ 'lg:!hidden xl:!flex': currentRoute().includes('explore') }">
          {{ 'i18n.SidebarMenu.explore' | translate }}
        </span>
      </a>
      <a
        class="navbar-link"
        routerLinkActive="active"
        [ngClass]="{ 'xl:!px-3 lg:justify-center xl:justify-start': currentRoute().includes('explore') }"
        [routerLink]="['/news']">
        <svg-icon
          class="text-slate-500 min-w-5 min-h-5 size-5"
          key="news-line"
          routerLinkActive="!text-accent bg-teal-50"></svg-icon>
        <span
          class="hidden lg:flex min-w-max transition-all truncate"
          [ngClass]="{ 'lg:!hidden xl:!flex': currentRoute().includes('explore') }">
          {{ 'i18n.SidebarMenu.news' | translate }}
        </span>
      </a>
      <a
        class="navbar-link"
        routerLinkActive="active"
        [ngClass]="{ 'xl:!px-3 lg:justify-center xl:justify-start': currentRoute().includes('explore') }"
        [routerLink]="['/coeditor']">
        <svg-icon
          class="text-slate-500 min-w-5 min-h-5 size-5"
          key="quill-pen-line"
          routerLinkActive="!text-accent bg-teal-50"></svg-icon>
        <span
          class="hidden lg:flex min-w-max transition-all"
          [ngClass]="{ 'lg:!hidden xl:!flex': currentRoute().includes('explore') }">
          {{ 'i18n.SidebarMenu.coeditor' | translate }}
        </span>
      </a>
      <div
        class="mt-auto hidden lg:block w-full max-w-40 text-sm leading-5 font-medium text-rest [&_a]:underline"
        [ngClass]="{ 'lg:!hidden xl:!block': currentRoute().includes('explore') }"
        [innerHTML]="'i18n.SidebarMenu.about' | translate"></div>
      @if (latestPeriodEnd$ | async; as latestPeriodEnd) {
        <div
          class="mt-4 hidden lg:flex flex-nowrap flex-col text-sm font-medium text-rest"
          [ngClass]="{ 'lg:!hidden xl:!flex': currentRoute().includes('explore') }">
          <span>{{ 'i18n.SidebarMenu.lastUpdate' | translate }}</span>
          <span class="min-w-max">{{ latestPeriodEnd | date: 'dd MMMM YYYY' }}</span>
        </div>
      }

      <div class="gap-4 text-rest text-sm mt-12">
        <!-- <svg-icon key="logout"></svg-icon>
        {{ 'i18n.SidebarMenu.quit' | translate }} -->
        <span
          class="hidden lg:flex min-w-max transition-all"
          [ngClass]="{ 'lg:!hidden xl:!flex': currentRoute().includes('explore') }">
          {{ ('i18n.SidebarMenu.version' | translate) + version }}
        </span>
      </div>
    </div>
  </nav>
  <router-outlet class="hidden" />
</div>
