/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { bulkCreateSegment } from '../fn/segments/bulk-create-segment';
import { BulkCreateSegment$Params } from '../fn/segments/bulk-create-segment';
import { bulkDeleteSegments } from '../fn/segments/bulk-delete-segments';
import { BulkDeleteSegments$Params } from '../fn/segments/bulk-delete-segments';
import { getSegment } from '../fn/segments/get-segment';
import { GetSegment$Params } from '../fn/segments/get-segment';
import { listSegments } from '../fn/segments/list-segments';
import { ListSegments$Params } from '../fn/segments/list-segments';
import { Segment } from '../models/segment';
import { SegmentResponsePage } from '../models/segment-response-page';

@Injectable({ providedIn: 'root' })
export class SegmentsApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listSegments()` */
  static readonly ListSegmentsPath = '/v0/segments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listSegments()` instead.
   *
   * This method doesn't expect any request body.
   */
  listSegments$Response(params?: ListSegments$Params, context?: HttpContext): Observable<StrictHttpResponse<SegmentResponsePage>> {
    return listSegments(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listSegments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listSegments(params?: ListSegments$Params, context?: HttpContext): Observable<SegmentResponsePage> {
    return this.listSegments$Response(params, context).pipe(
      map((r: StrictHttpResponse<SegmentResponsePage>): SegmentResponsePage => r.body)
    );
  }

  /** Path part for operation `bulkCreateSegment()` */
  static readonly BulkCreateSegmentPath = '/v0/segments/bulk';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkCreateSegment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkCreateSegment$Response(params: BulkCreateSegment$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Segment>>> {
    return bulkCreateSegment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkCreateSegment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkCreateSegment(params: BulkCreateSegment$Params, context?: HttpContext): Observable<Array<Segment>> {
    return this.bulkCreateSegment$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Segment>>): Array<Segment> => r.body)
    );
  }

  /** Path part for operation `getSegment()` */
  static readonly GetSegmentPath = '/v0/segments/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSegment()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSegment$Response(params: GetSegment$Params, context?: HttpContext): Observable<StrictHttpResponse<Segment>> {
    return getSegment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSegment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSegment(params: GetSegment$Params, context?: HttpContext): Observable<Segment> {
    return this.getSegment$Response(params, context).pipe(
      map((r: StrictHttpResponse<Segment>): Segment => r.body)
    );
  }

  /** Path part for operation `bulkDeleteSegments()` */
  static readonly BulkDeleteSegmentsPath = '/v0/segments/bulk-delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkDeleteSegments()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkDeleteSegments$Response(params: BulkDeleteSegments$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return bulkDeleteSegments(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkDeleteSegments$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkDeleteSegments(params: BulkDeleteSegments$Params, context?: HttpContext): Observable<void> {
    return this.bulkDeleteSegments$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
