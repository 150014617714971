import { AsyncPipe, DatePipe, NgClass } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { RouterLinkActive, RouterLink, RouterOutlet, Router, NavigationEnd } from '@angular/router';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { distinctUntilChanged, filter, map, shareReplay } from 'rxjs';
import { GraphService } from 'src/app/services/graph.service';
import { addDays } from 'src/app/utils/date';
import { environment } from 'src/environment/environment';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss',
  standalone: true,
  imports: [
    AsyncPipe,
    DatePipe,
    NgClass,
    RouterLinkActive,
    RouterLink,
    RouterOutlet,
    SvgIconComponent,
    TranslateModule,
  ],
})
export class MainComponent {
  readonly version = environment.appVersion;
  private readonly graphService = inject(GraphService);
  private readonly router = inject(Router);
  readonly currentRoute = signal(this.router.url);
  readonly periods$ = this.graphService.listPeriods({ sorting: ['-endDate', '-startDate'] }).pipe(
    map(({ items }) => items),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly latestPeriodEnd$ = this.periods$.pipe(
    map((periods) => periods.at(0)),
    filter((x): x is NonNullable<typeof x> => !!x),
    map((x) => addDays(x.endDate, -1)),
  );

  constructor() {
    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        map((event) => event.urlAfterRedirects.split('?')[0]),
        distinctUntilChanged(),
      )
      .subscribe(this.currentRoute.set);
  }
}
