/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { bulkDeleteDraftArticle } from '../fn/draft-articles/bulk-delete-draft-article';
import { BulkDeleteDraftArticle$Params } from '../fn/draft-articles/bulk-delete-draft-article';
import { createDraftArticle } from '../fn/draft-articles/create-draft-article';
import { CreateDraftArticle$Params } from '../fn/draft-articles/create-draft-article';
import { DraftArticle } from '../models/draft-article';
import { DraftArticleBase } from '../models/draft-article-base';
import { DraftArticleResponseBasePage } from '../models/draft-article-response-base-page';
import { getDraftArticle } from '../fn/draft-articles/get-draft-article';
import { GetDraftArticle$Params } from '../fn/draft-articles/get-draft-article';
import { listDraftArticles } from '../fn/draft-articles/list-draft-articles';
import { ListDraftArticles$Params } from '../fn/draft-articles/list-draft-articles';
import { updateDraftArticle } from '../fn/draft-articles/update-draft-article';
import { UpdateDraftArticle$Params } from '../fn/draft-articles/update-draft-article';

@Injectable({ providedIn: 'root' })
export class DraftArticlesApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listDraftArticles()` */
  static readonly ListDraftArticlesPath = '/v0/draft-articles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listDraftArticles()` instead.
   *
   * This method doesn't expect any request body.
   */
  listDraftArticles$Response(params?: ListDraftArticles$Params, context?: HttpContext): Observable<StrictHttpResponse<DraftArticleResponseBasePage>> {
    return listDraftArticles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listDraftArticles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listDraftArticles(params?: ListDraftArticles$Params, context?: HttpContext): Observable<DraftArticleResponseBasePage> {
    return this.listDraftArticles$Response(params, context).pipe(
      map((r: StrictHttpResponse<DraftArticleResponseBasePage>): DraftArticleResponseBasePage => r.body)
    );
  }

  /** Path part for operation `createDraftArticle()` */
  static readonly CreateDraftArticlePath = '/v0/draft-articles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createDraftArticle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDraftArticle$Response(params: CreateDraftArticle$Params, context?: HttpContext): Observable<StrictHttpResponse<DraftArticleBase>> {
    return createDraftArticle(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createDraftArticle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDraftArticle(params: CreateDraftArticle$Params, context?: HttpContext): Observable<DraftArticleBase> {
    return this.createDraftArticle$Response(params, context).pipe(
      map((r: StrictHttpResponse<DraftArticleBase>): DraftArticleBase => r.body)
    );
  }

  /** Path part for operation `getDraftArticle()` */
  static readonly GetDraftArticlePath = '/v0/draft-articles/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDraftArticle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDraftArticle$Response(params: GetDraftArticle$Params, context?: HttpContext): Observable<StrictHttpResponse<DraftArticle>> {
    return getDraftArticle(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDraftArticle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDraftArticle(params: GetDraftArticle$Params, context?: HttpContext): Observable<DraftArticle> {
    return this.getDraftArticle$Response(params, context).pipe(
      map((r: StrictHttpResponse<DraftArticle>): DraftArticle => r.body)
    );
  }

  /** Path part for operation `updateDraftArticle()` */
  static readonly UpdateDraftArticlePath = '/v0/draft-articles/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDraftArticle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDraftArticle$Response(params: UpdateDraftArticle$Params, context?: HttpContext): Observable<StrictHttpResponse<DraftArticle>> {
    return updateDraftArticle(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateDraftArticle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDraftArticle(params: UpdateDraftArticle$Params, context?: HttpContext): Observable<DraftArticle> {
    return this.updateDraftArticle$Response(params, context).pipe(
      map((r: StrictHttpResponse<DraftArticle>): DraftArticle => r.body)
    );
  }

  /** Path part for operation `bulkDeleteDraftArticle()` */
  static readonly BulkDeleteDraftArticlePath = '/v0/draft-articles/bulk-delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkDeleteDraftArticle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkDeleteDraftArticle$Response(params: BulkDeleteDraftArticle$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return bulkDeleteDraftArticle(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkDeleteDraftArticle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkDeleteDraftArticle(params: BulkDeleteDraftArticle$Params, context?: HttpContext): Observable<void> {
    return this.bulkDeleteDraftArticle$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
