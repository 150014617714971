/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createPendingArticle } from '../fn/pending-articles/create-pending-article';
import { CreatePendingArticle$Params } from '../fn/pending-articles/create-pending-article';
import { listPendingArticles } from '../fn/pending-articles/list-pending-articles';
import { ListPendingArticles$Params } from '../fn/pending-articles/list-pending-articles';
import { PendingArticle } from '../models/pending-article';
import { PendingArticleResponsePage } from '../models/pending-article-response-page';

@Injectable({ providedIn: 'root' })
export class PendingArticlesApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listPendingArticles()` */
  static readonly ListPendingArticlesPath = '/v0/pending-articles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listPendingArticles()` instead.
   *
   * This method doesn't expect any request body.
   */
  listPendingArticles$Response(params?: ListPendingArticles$Params, context?: HttpContext): Observable<StrictHttpResponse<PendingArticleResponsePage>> {
    return listPendingArticles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listPendingArticles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listPendingArticles(params?: ListPendingArticles$Params, context?: HttpContext): Observable<PendingArticleResponsePage> {
    return this.listPendingArticles$Response(params, context).pipe(
      map((r: StrictHttpResponse<PendingArticleResponsePage>): PendingArticleResponsePage => r.body)
    );
  }

  /** Path part for operation `createPendingArticle()` */
  static readonly CreatePendingArticlePath = '/v0/pending-articles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPendingArticle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPendingArticle$Response(params: CreatePendingArticle$Params, context?: HttpContext): Observable<StrictHttpResponse<PendingArticle>> {
    return createPendingArticle(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPendingArticle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPendingArticle(params: CreatePendingArticle$Params, context?: HttpContext): Observable<PendingArticle> {
    return this.createPendingArticle$Response(params, context).pipe(
      map((r: StrictHttpResponse<PendingArticle>): PendingArticle => r.body)
    );
  }

}
