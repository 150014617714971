/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createTrackedPublisher } from '../fn/publishers/create-tracked-publisher';
import { CreateTrackedPublisher$Params } from '../fn/publishers/create-tracked-publisher';
import { getPublisher } from '../fn/publishers/get-publisher';
import { GetPublisher$Params } from '../fn/publishers/get-publisher';
import { listPublishers } from '../fn/publishers/list-publishers';
import { ListPublishers$Params } from '../fn/publishers/list-publishers';
import { listTrackedPublishers } from '../fn/publishers/list-tracked-publishers';
import { ListTrackedPublishers$Params } from '../fn/publishers/list-tracked-publishers';
import { Publisher } from '../models/publisher';
import { PublisherResponsePage } from '../models/publisher-response-page';
import { TrackedPublisher } from '../models/tracked-publisher';
import { TrackedPublisherResponsePage } from '../models/tracked-publisher-response-page';

@Injectable({ providedIn: 'root' })
export class PublishersApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listPublishers()` */
  static readonly ListPublishersPath = '/v0/publishers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listPublishers()` instead.
   *
   * This method doesn't expect any request body.
   */
  listPublishers$Response(params?: ListPublishers$Params, context?: HttpContext): Observable<StrictHttpResponse<PublisherResponsePage>> {
    return listPublishers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listPublishers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listPublishers(params?: ListPublishers$Params, context?: HttpContext): Observable<PublisherResponsePage> {
    return this.listPublishers$Response(params, context).pipe(
      map((r: StrictHttpResponse<PublisherResponsePage>): PublisherResponsePage => r.body)
    );
  }

  /** Path part for operation `createTrackedPublisher()` */
  static readonly CreateTrackedPublisherPath = '/v0/publishers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTrackedPublisher()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTrackedPublisher$Response(params: CreateTrackedPublisher$Params, context?: HttpContext): Observable<StrictHttpResponse<TrackedPublisher>> {
    return createTrackedPublisher(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTrackedPublisher$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTrackedPublisher(params: CreateTrackedPublisher$Params, context?: HttpContext): Observable<TrackedPublisher> {
    return this.createTrackedPublisher$Response(params, context).pipe(
      map((r: StrictHttpResponse<TrackedPublisher>): TrackedPublisher => r.body)
    );
  }

  /** Path part for operation `listTrackedPublishers()` */
  static readonly ListTrackedPublishersPath = '/v0/publishers/tracked';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listTrackedPublishers()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTrackedPublishers$Response(params?: ListTrackedPublishers$Params, context?: HttpContext): Observable<StrictHttpResponse<TrackedPublisherResponsePage>> {
    return listTrackedPublishers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listTrackedPublishers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTrackedPublishers(params?: ListTrackedPublishers$Params, context?: HttpContext): Observable<TrackedPublisherResponsePage> {
    return this.listTrackedPublishers$Response(params, context).pipe(
      map((r: StrictHttpResponse<TrackedPublisherResponsePage>): TrackedPublisherResponsePage => r.body)
    );
  }

  /** Path part for operation `getPublisher()` */
  static readonly GetPublisherPath = '/v0/publishers/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublisher()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublisher$Response(params: GetPublisher$Params, context?: HttpContext): Observable<StrictHttpResponse<Publisher>> {
    return getPublisher(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPublisher$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublisher(params: GetPublisher$Params, context?: HttpContext): Observable<Publisher> {
    return this.getPublisher$Response(params, context).pipe(
      map((r: StrictHttpResponse<Publisher>): Publisher => r.body)
    );
  }

}
