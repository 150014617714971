import { Injectable, inject } from '@angular/core';
import { ExploreGraphApi } from '../api/backend-connector/services';
import { GetNodes$Params } from '../api/backend-connector/fn/explore-graph/get-nodes';
import { ListPeriods$Params } from '../api/backend-connector/fn/explore-graph/list-periods';

@Injectable({
  providedIn: 'root',
})
export class GraphService {
  readonly graphApi = inject(ExploreGraphApi);

  listNodes(params: GetNodes$Params) {
    return this.graphApi.getNodes(params);
  }

  listPeriods(params?: ListPeriods$Params) {
    return this.graphApi.listPeriods(params);
  }
}
