/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { bulkCreateEmbedding } from '../fn/embeddings/bulk-create-embedding';
import { BulkCreateEmbedding$Params } from '../fn/embeddings/bulk-create-embedding';
import { Embedding } from '../models/embedding';
import { EmbeddingResponsePage } from '../models/embedding-response-page';
import { listEmbeddings } from '../fn/embeddings/list-embeddings';
import { ListEmbeddings$Params } from '../fn/embeddings/list-embeddings';
import { searchEmbeddings } from '../fn/embeddings/search-embeddings';
import { SearchEmbeddings$Params } from '../fn/embeddings/search-embeddings';
import { SearchResponsePage } from '../models/search-response-page';

@Injectable({ providedIn: 'root' })
export class EmbeddingsApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listEmbeddings()` */
  static readonly ListEmbeddingsPath = '/v0/embeddings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listEmbeddings()` instead.
   *
   * This method doesn't expect any request body.
   */
  listEmbeddings$Response(params?: ListEmbeddings$Params, context?: HttpContext): Observable<StrictHttpResponse<EmbeddingResponsePage>> {
    return listEmbeddings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listEmbeddings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listEmbeddings(params?: ListEmbeddings$Params, context?: HttpContext): Observable<EmbeddingResponsePage> {
    return this.listEmbeddings$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmbeddingResponsePage>): EmbeddingResponsePage => r.body)
    );
  }

  /** Path part for operation `bulkCreateEmbedding()` */
  static readonly BulkCreateEmbeddingPath = '/v0/embeddings/bulk';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkCreateEmbedding()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkCreateEmbedding$Response(params: BulkCreateEmbedding$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Embedding>>> {
    return bulkCreateEmbedding(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkCreateEmbedding$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkCreateEmbedding(params: BulkCreateEmbedding$Params, context?: HttpContext): Observable<Array<Embedding>> {
    return this.bulkCreateEmbedding$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Embedding>>): Array<Embedding> => r.body)
    );
  }

  /** Path part for operation `searchEmbeddings()` */
  static readonly SearchEmbeddingsPath = '/v0/embeddings/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchEmbeddings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  searchEmbeddings$Response(params: SearchEmbeddings$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchResponsePage>> {
    return searchEmbeddings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchEmbeddings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  searchEmbeddings(params: SearchEmbeddings$Params, context?: HttpContext): Observable<SearchResponsePage> {
    return this.searchEmbeddings$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchResponsePage>): SearchResponsePage => r.body)
    );
  }

}
