/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Conversation } from '../models/conversation';
import { ConversationResponsePage } from '../models/conversation-response-page';
import { createConversation } from '../fn/conversations/create-conversation';
import { CreateConversation$Params } from '../fn/conversations/create-conversation';
import { deleteConversation } from '../fn/conversations/delete-conversation';
import { DeleteConversation$Params } from '../fn/conversations/delete-conversation';
import { getConversation } from '../fn/conversations/get-conversation';
import { GetConversation$Params } from '../fn/conversations/get-conversation';
import { listConversations } from '../fn/conversations/list-conversations';
import { ListConversations$Params } from '../fn/conversations/list-conversations';
import { setConversationTitle } from '../fn/conversations/set-conversation-title';
import { SetConversationTitle$Params } from '../fn/conversations/set-conversation-title';

@Injectable({ providedIn: 'root' })
export class ConversationsApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listConversations()` */
  static readonly ListConversationsPath = '/v0/conversations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listConversations()` instead.
   *
   * This method doesn't expect any request body.
   */
  listConversations$Response(params?: ListConversations$Params, context?: HttpContext): Observable<StrictHttpResponse<ConversationResponsePage>> {
    return listConversations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listConversations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listConversations(params?: ListConversations$Params, context?: HttpContext): Observable<ConversationResponsePage> {
    return this.listConversations$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConversationResponsePage>): ConversationResponsePage => r.body)
    );
  }

  /** Path part for operation `createConversation()` */
  static readonly CreateConversationPath = '/v0/conversations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createConversation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createConversation$Response(params: CreateConversation$Params, context?: HttpContext): Observable<StrictHttpResponse<Conversation>> {
    return createConversation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createConversation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createConversation(params: CreateConversation$Params, context?: HttpContext): Observable<Conversation> {
    return this.createConversation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Conversation>): Conversation => r.body)
    );
  }

  /** Path part for operation `getConversation()` */
  static readonly GetConversationPath = '/v0/conversations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConversation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConversation$Response(params: GetConversation$Params, context?: HttpContext): Observable<StrictHttpResponse<Conversation>> {
    return getConversation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConversation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConversation(params: GetConversation$Params, context?: HttpContext): Observable<Conversation> {
    return this.getConversation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Conversation>): Conversation => r.body)
    );
  }

  /** Path part for operation `setConversationTitle()` */
  static readonly SetConversationTitlePath = '/v0/conversations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setConversationTitle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setConversationTitle$Response(params: SetConversationTitle$Params, context?: HttpContext): Observable<StrictHttpResponse<Conversation>> {
    return setConversationTitle(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setConversationTitle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setConversationTitle(params: SetConversationTitle$Params, context?: HttpContext): Observable<Conversation> {
    return this.setConversationTitle$Response(params, context).pipe(
      map((r: StrictHttpResponse<Conversation>): Conversation => r.body)
    );
  }

  /** Path part for operation `deleteConversation()` */
  static readonly DeleteConversationPath = '/v0/conversations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteConversation()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteConversation$Response(params: DeleteConversation$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteConversation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteConversation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteConversation(params: DeleteConversation$Params, context?: HttpContext): Observable<void> {
    return this.deleteConversation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
