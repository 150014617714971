/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { WebContentResponsePage } from '../../models/web-content-response-page';

export interface ListWebContents$Params {
  originalUrl?: string;
  offset?: number;
  limit?: number;
  sorting?: Array<'id' | '-id' | 'originalUrl' | '-originalUrl' | 'url' | '-url' | 'status' | '-status' | 'headers' | '-headers' | 'error' | '-error' | 'createdAt' | '-createdAt' | 'updatedAt' | '-updatedAt'>;
}

export function listWebContents(http: HttpClient, rootUrl: string, params?: ListWebContents$Params, context?: HttpContext): Observable<StrictHttpResponse<WebContentResponsePage>> {
  const rb = new RequestBuilder(rootUrl, listWebContents.PATH, 'get');
  if (params) {
    rb.query('originalUrl', params.originalUrl, {"style":"form"});
    rb.query('offset', params.offset, {"style":"form"});
    rb.query('limit', params.limit, {"style":"form"});
    rb.query('sorting', params.sorting, {"style":"form"});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<WebContentResponsePage>;
    })
  );
}

listWebContents.PATH = '/v0/web-contents';
