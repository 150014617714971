/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TrackedPublisherResponsePage } from '../../models/tracked-publisher-response-page';

export interface ListTrackedPublishers$Params {
  query?: string;
  offset?: number;
  limit?: number;
  sorting?: Array<'id' | '-id' | 'link' | '-link' | 'userId' | '-userId' | 'publisherId' | '-publisherId'>;
}

export function listTrackedPublishers(http: HttpClient, rootUrl: string, params?: ListTrackedPublishers$Params, context?: HttpContext): Observable<StrictHttpResponse<TrackedPublisherResponsePage>> {
  const rb = new RequestBuilder(rootUrl, listTrackedPublishers.PATH, 'get');
  if (params) {
    rb.query('query', params.query, {"style":"form"});
    rb.query('offset', params.offset, {"style":"form"});
    rb.query('limit', params.limit, {"style":"form"});
    rb.query('sorting', params.sorting, {"style":"form"});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TrackedPublisherResponsePage>;
    })
  );
}

listTrackedPublishers.PATH = '/v0/publishers/tracked';
