/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CreatePendingArticleRequest } from '../../models/create-pending-article-request';
import { PendingArticle } from '../../models/pending-article';

export interface CreatePendingArticle$Params {
      body: CreatePendingArticleRequest
}

export function createPendingArticle(http: HttpClient, rootUrl: string, params: CreatePendingArticle$Params, context?: HttpContext): Observable<StrictHttpResponse<PendingArticle>> {
  const rb = new RequestBuilder(rootUrl, createPendingArticle.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PendingArticle>;
    })
  );
}

createPendingArticle.PATH = '/v0/pending-articles';
