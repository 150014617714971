/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { FeedbackResponsePage } from '../models/feedback-response-page';
import { getMe } from '../fn/users/get-me';
import { GetMe$Params } from '../fn/users/get-me';
import { listFeedbacks } from '../fn/users/list-feedbacks';
import { ListFeedbacks$Params } from '../fn/users/list-feedbacks';
import { Me } from '../models/me';

@Injectable({ providedIn: 'root' })
export class UsersApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getMe()` */
  static readonly GetMePath = '/v0/users/me';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMe()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMe$Response(params?: GetMe$Params, context?: HttpContext): Observable<StrictHttpResponse<Me>> {
    return getMe(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMe(params?: GetMe$Params, context?: HttpContext): Observable<Me> {
    return this.getMe$Response(params, context).pipe(
      map((r: StrictHttpResponse<Me>): Me => r.body)
    );
  }

  /** Path part for operation `listFeedbacks_1()` */
  static readonly ListFeedbacks_1Path = '/v0/users/feedbacks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listFeedbacks()` instead.
   *
   * This method doesn't expect any request body.
   */
  listFeedbacks$Response(params?: ListFeedbacks$Params, context?: HttpContext): Observable<StrictHttpResponse<FeedbackResponsePage>> {
    return listFeedbacks(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listFeedbacks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listFeedbacks(params?: ListFeedbacks$Params, context?: HttpContext): Observable<FeedbackResponsePage> {
    return this.listFeedbacks$Response(params, context).pipe(
      map((r: StrictHttpResponse<FeedbackResponsePage>): FeedbackResponsePage => r.body)
    );
  }

}
