/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getHealth } from '../fn/diagnostics/get-health';
import { GetHealth$Params } from '../fn/diagnostics/get-health';
import { getVersion } from '../fn/diagnostics/get-version';
import { GetVersion$Params } from '../fn/diagnostics/get-version';

@Injectable({ providedIn: 'root' })
export class DiagnosticsApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getVersion()` */
  static readonly GetVersionPath = '/v0/diag/version';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVersion()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVersion$Response(params?: GetVersion$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getVersion(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVersion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVersion(params?: GetVersion$Params, context?: HttpContext): Observable<string> {
    return this.getVersion$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getHealth()` */
  static readonly GetHealthPath = '/v0/diag/health';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHealth()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHealth$Response(params?: GetHealth$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getHealth(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHealth$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHealth(params?: GetHealth$Params, context?: HttpContext): Observable<string> {
    return this.getHealth$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
