/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ConversationResponsePage } from '../../models/conversation-response-page';

export interface ListConversations$Params {
  offset?: number;
  limit?: number;
  sorting?: Array<'id' | '-id' | 'title' | '-title' | 'roomId' | '-roomId' | 'userId' | '-userId' | 'draftArticleId' | '-draftArticleId' | 'draftArticleTitle' | '-draftArticleTitle' | 'createdAt' | '-createdAt' | 'updatedAt' | '-updatedAt'>;
}

export function listConversations(http: HttpClient, rootUrl: string, params?: ListConversations$Params, context?: HttpContext): Observable<StrictHttpResponse<ConversationResponsePage>> {
  const rb = new RequestBuilder(rootUrl, listConversations.PATH, 'get');
  if (params) {
    rb.query('offset', params.offset, {"style":"form"});
    rb.query('limit', params.limit, {"style":"form"});
    rb.query('sorting', params.sorting, {"style":"form"});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ConversationResponsePage>;
    })
  );
}

listConversations.PATH = '/v0/conversations';
