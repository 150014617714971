/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createWebContent } from '../fn/web-contents/create-web-content';
import { CreateWebContent$Params } from '../fn/web-contents/create-web-content';
import { getWebContent } from '../fn/web-contents/get-web-content';
import { GetWebContent$Params } from '../fn/web-contents/get-web-content';
import { listWebContents } from '../fn/web-contents/list-web-contents';
import { ListWebContents$Params } from '../fn/web-contents/list-web-contents';
import { WebContentFullResponse } from '../models/web-content-full-response';
import { WebContentResponse } from '../models/web-content-response';
import { WebContentResponsePage } from '../models/web-content-response-page';

@Injectable({ providedIn: 'root' })
export class WebContentsApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listWebContents()` */
  static readonly ListWebContentsPath = '/v0/web-contents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listWebContents()` instead.
   *
   * This method doesn't expect any request body.
   */
  listWebContents$Response(params?: ListWebContents$Params, context?: HttpContext): Observable<StrictHttpResponse<WebContentResponsePage>> {
    return listWebContents(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listWebContents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listWebContents(params?: ListWebContents$Params, context?: HttpContext): Observable<WebContentResponsePage> {
    return this.listWebContents$Response(params, context).pipe(
      map((r: StrictHttpResponse<WebContentResponsePage>): WebContentResponsePage => r.body)
    );
  }

  /** Path part for operation `createWebContent()` */
  static readonly CreateWebContentPath = '/v0/web-contents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createWebContent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createWebContent$Response(params: CreateWebContent$Params, context?: HttpContext): Observable<StrictHttpResponse<WebContentResponse>> {
    return createWebContent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createWebContent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createWebContent(params: CreateWebContent$Params, context?: HttpContext): Observable<WebContentResponse> {
    return this.createWebContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<WebContentResponse>): WebContentResponse => r.body)
    );
  }

  /** Path part for operation `getWebContent()` */
  static readonly GetWebContentPath = '/v0/web-contents/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWebContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWebContent$Response(params: GetWebContent$Params, context?: HttpContext): Observable<StrictHttpResponse<WebContentFullResponse>> {
    return getWebContent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWebContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWebContent(params: GetWebContent$Params, context?: HttpContext): Observable<WebContentFullResponse> {
    return this.getWebContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<WebContentFullResponse>): WebContentFullResponse => r.body)
    );
  }

}
